import 'select2';
// import 'select2/dist/css/select2.css'; 

const initSelect2 = () => {

    if ($(".select2").length) {
        $(".select2").select2();
    }


    if ($(".select2_tags").length) {
        $(".select2_tags").select2({
            tags: true
        });
    }
}
export { initSelect2 };

import 'lightgallery.js';
// import 'lightgallery.js/dist/css/lightgallery.css'; 

const initLightGallery = () => {

    if ($("#gallery").length) {
        lightGallery(document.getElementById('gallery'), {download: false}); 
    }

}
export { initLightGallery };

import flatpickr from "flatpickr";
// import 'flatpickr/dist/flatpickr.min.css'; 

const initDatePicker = () => {
	
    if ($(".datepicker").length) {
        flatpickr(".datepicker");
    }
}
export { initDatePicker };

import Isotope from 'isotope-layout';

const initIsotope = () => {
	let iso;
    if ($(".grid").length) {

    	var grid = document.querySelector('.grid');
    	iso = new Isotope( grid, {
		  itemSelector: '.grid-item',
		  masonry: {
		    columnWidth: 320,
  			horizontalOrder: true,
  			fitWidth: true
		  }
		});
    }

    if ($("#search_box").length) {
    	// const search_box_clear = document.querySelector("#search_box--clear");
    	var qsRegex;
    	var $grid = document.querySelector('.grid');
		const queryString = window.location.search;
		const urlParams = new URLSearchParams(queryString);
		const searchParam = urlParams.get('search');
		

		// search_box_clear.addEventListener("click", ()=>{
		// 	var $quicksearch = $('#search_box');
		// 	$quicksearch.val("");
		// 	iso.arrange({ filter: '*'})
		// })

		function match_all_words(el)
		{
			var search_box = document.querySelector("#search_box");
	  		var words = search_box.value.split(" ").filter(word=>{ return word.length>0 });
	  		
			var results = words.map(word => {
				var output = el.dataset["filter"].toLowerCase().includes(word.toLowerCase());
				return output;
	  		})
	  		return results.every((e)=> e);

		}

		var $quicksearch = $('#search_box').keyup( debounce( function() {
		  iso.arrange({
		  	filter: function(el) {
				
		  		return match_all_words(this);
		  	}
			})
		}, 200 ) );

		if(searchParam)
		{
			var $quicksearch = $('#search_box');
			$quicksearch.val(searchParam);
			iso.arrange({
			  	filter: function(el) {
			  		return match_all_words(el);
			  	}
			})
		}

		// debounce so filtering doesn't happen every millisecond
		function debounce( fn, threshold ) {
		  var timeout;
		  threshold = threshold || 100;
		  return function debounced() {
		    clearTimeout( timeout );
		    var args = arguments;
		    var _this = this;
		    function delayed() {
		      fn.apply( _this, args );
		    }
		    timeout = setTimeout( delayed, threshold );
		  };
		}

		if (iso && $(".keyword_suggestion").length) {
	    	
	    	$(".keyword_suggestion").click(function(){
	    		
	    		const stripped_text = $( this ).text();
				$quicksearch.val(stripped_text);
		    	let qsRegex = new RegExp( $quicksearch.val(), 'gi' );
		    	iso.arrange({
				  	filter: function(el) {
		    			return match_all_words(this);
				  	}
				})
				
	    	})
	    }
    }

	if ($("#keyword_button__show_all_category").length) {
		const show_all_btn = document.querySelector('#keyword_button__show_all_category');
		const hide_tags_btn = document.querySelector('#keyword_button__hide_tags_category');
		show_all_btn.classList.remove("d-none");
		show_all_btn.addEventListener("click", ()=>{
			show_all_btn.classList.add("d-none");
			hide_tags_btn.classList.remove("d-none");
			const category_suggestions = document.querySelectorAll('.category_suggestion_container > .keyword_suggestion.more');
			category_suggestions.forEach((k)=>{
				k.classList.remove("d-none");
			})
		})
		hide_tags_btn.addEventListener("click", ()=>{
			show_all_btn.classList.remove("d-none");
			hide_tags_btn.classList.add("d-none");
			const category_suggestions = document.querySelectorAll('.category_suggestion_container > .keyword_suggestion.more');
			category_suggestions.forEach((k)=>{
				k.classList.add("d-none");
			})
		})
    }    

    if ($("#keyword_button__show_all_keyword").length) {
		const show_all_btn = document.querySelector('#keyword_button__show_all_keyword');
		const hide_tags_btn = document.querySelector('#keyword_button__hide_tags_keyword');
		show_all_btn.classList.remove("d-none");
		show_all_btn.addEventListener("click", ()=>{
			show_all_btn.classList.add("d-none");
			hide_tags_btn.classList.remove("d-none");
			const keyword_suggestions = document.querySelectorAll('.keyword_suggestion_container > .keyword_suggestion.more');
			keyword_suggestions.forEach((k)=>{
				k.classList.remove("d-none");
			})
		})
		hide_tags_btn.addEventListener("click", ()=>{
			show_all_btn.classList.remove("d-none");
			hide_tags_btn.classList.add("d-none");
			const keyword_suggestions = document.querySelectorAll('.keyword_suggestion_container > .keyword_suggestion.more');
			keyword_suggestions.forEach((k)=>{
				k.classList.add("d-none");
			})
		})
    }


}
export { initIsotope };
